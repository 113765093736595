import { usePrivacyAnimation } from "../../hooks/usePrivacyAnimation";
import { Image } from "../Image";
import {
  LandingPrivacyImg,
  LandingPrivacyStyled,
  LandingPrivacyTitle,
  LandingPrivacyTitleList,
} from "./LandingPrivacy.style";

const LandingPrivacy = ({ title, list, imgs }: any) => {
  usePrivacyAnimation();
  return (
    <LandingPrivacyStyled id="lock_privacy_ref">
      <div>
        <LandingPrivacyTitle>{title}</LandingPrivacyTitle>
        <LandingPrivacyTitleList>
          {list?.map((item: any, index: number) => {
            return <li key={index}>{item}</li>;
          })}
        </LandingPrivacyTitleList>
      </div>
      <LandingPrivacyImg>
        <Image
          id="lockFront_ref"
          className="lockFront"
          {...imgs?.[0]}
          loading={imgs?.[0]?.loading || "lazy"}
        />
        <Image
          id="lockFront2_ref"
          className="lockFront2"
          {...imgs?.[1]}
          loading={imgs?.[1]?.loading || "lazy"}
        />
        <Image
          id="lockVer_ref"
          className="lockVer"
          {...imgs?.[2]}
          loading={imgs?.[2]?.loading || "lazy"}
        />
        <Image
          id="lock_ref"
          className="lockUpper"
          {...imgs?.[3]}
          loading={imgs?.[3]?.loading || "lazy"}
        />
      </LandingPrivacyImg>
    </LandingPrivacyStyled>
  );
};

export default LandingPrivacy;
