import LandingPrivacy from "../LandingPrivacy/LandingPrivacy";
import { LandingWhiteSec } from "../../shared-styled-components";

const PrivacySection = (props: any) => {
  return (
    <LandingWhiteSec padding="84px 0 0 0" id="privacy_ref">
      <LandingPrivacy {...props} />
    </LandingWhiteSec>
  );
};

export default PrivacySection;
